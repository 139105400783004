<template>
  <div
    class="tooltip-container"
    @mouseover="showTooltip"
    @mouseleave="hideTooltip"
  >
    <i class="tooltip-icon">ℹ️</i>
    <div v-if="visible" class="tooltip">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    showTooltip() {
      this.visible = true;
    },
    hideTooltip() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: block;
}

.tooltip-icon {
  cursor: pointer;
  font-size: 16px;
  color: #007bff;
  margin-right: 0;
  position: absolute;
  padding-left: 5px;
}

.tooltip {
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  white-space: normal;
  word-wrap: break-word;
  max-width: 350px;
  min-width: 250px;
  font-size: 14px;
  opacity: 0.96;
  text-align: center;
  z-index: 10;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

@media (min-width: 768px) {
  .tooltip-icon {
    bottom: 0px;
  }
  .tooltip {
    bottom: 40px;
  }
}

@media (max-width: 768px) {
  .tooltip {
    bottom: auto;
    top: 20px;
    left: 0;
    right: 0;
    transform: translateX(-80%);
    min-width: 220px;
  }

  .tooltip::after {
    top: auto;
    bottom: 100%;
    border-color: transparent transparent #333 transparent;
  }

  .tooltip-icon {
    bottom: -10px;
  }
}
@media (max-width: 575px) {
  .tooltip {
    bottom: auto;
    top: 120%;
    left: 0;
    right: 0;
    transform: translateX(-80%);
    min-width: 200px;
  }
  .tooltip-container {
    position: relative;
    display: inline-block;
  }
  .tooltip-icon {
    font-size: 12px;
    position: relative;
    bottom: 0;
    padding-left: 2px;
  }
}
@media (max-width: 440px) {
  .tooltip {
    bottom: auto;
    top: 110%;
    left: -80%;
    transform: translateX(-70%);
    min-width: 190px;
  }
}
</style>
